/**
 * @generated SignedSource<<ec7f5fbdd6c89fdd056b46b9369de15c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { certificates_to_verify_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type ProcessingCertCardQuery$variables = {
  certsToVerifyWhere: certificates_to_verify_bool_exp;
};
export type ProcessingCertCardQuery$data = {
  readonly certificates_to_verify_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly back_image: {
          readonly url: string;
        } | null | undefined;
        readonly back_image_id: string | null | undefined;
        readonly created_at: string;
        readonly front_image: {
          readonly url: string;
        } | null | undefined;
        readonly front_image_id: string;
        readonly id: string;
        readonly pk: string;
        readonly project: {
          readonly general_contractor: {
            readonly name: string;
          };
          readonly name: string;
        } | null | undefined;
        readonly uploaded_by_user: {
          readonly employee: {
            readonly employee_title: {
              readonly name: {
                readonly en: string;
              };
            } | null | undefined;
            readonly general_contractor: {
              readonly name: string;
            };
          } | null | undefined;
          readonly id: string;
          readonly name: string;
          readonly pk: string;
          readonly role: string;
          readonly subcontractor_employee: {
            readonly employee_title: {
              readonly name: {
                readonly en: string;
              };
            } | null | undefined;
            readonly subcontractor: {
              readonly name: string;
            };
          } | null | undefined;
          readonly worker: {
            readonly current_worker_role: string;
            readonly subcontractor: {
              readonly name: string;
            } | null | undefined;
            readonly worker_title: {
              readonly translation: {
                readonly en: string;
              };
            } | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly worker_id: string;
      };
    }>;
  };
};
export type ProcessingCertCardQuery = {
  response: ProcessingCertCardQuery$data;
  variables: ProcessingCertCardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "certsToVerifyWhere"
  }
],
v1 = {
  "kind": "Variable",
  "name": "where",
  "variableName": "certsToVerifyWhere"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "worker_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = [
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "general_contractor",
  "kind": "LinkedField",
  "name": "general_contractor",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "front_image_id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v11 = [
  (v10/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v14 = [
  (v13/*: any*/)
],
v15 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "text_translation",
    "kind": "LinkedField",
    "name": "name",
    "plural": false,
    "selections": (v14/*: any*/),
    "storageKey": null
  }
],
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "subcontractor",
  "kind": "LinkedField",
  "name": "subcontractor",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "current_worker_role",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "back_image_id",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v22 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  },
  (v1/*: any*/)
],
v23 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v24 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v25 = [
  (v6/*: any*/),
  (v2/*: any*/)
],
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "general_contractor",
  "kind": "LinkedField",
  "name": "general_contractor",
  "plural": false,
  "selections": (v25/*: any*/),
  "storageKey": null
},
v27 = [
  (v10/*: any*/),
  (v2/*: any*/)
],
v28 = [
  (v13/*: any*/),
  (v2/*: any*/)
],
v29 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "text_translation",
    "kind": "LinkedField",
    "name": "name",
    "plural": false,
    "selections": (v28/*: any*/),
    "storageKey": null
  },
  (v2/*: any*/)
],
v30 = {
  "alias": null,
  "args": null,
  "concreteType": "subcontractor",
  "kind": "LinkedField",
  "name": "subcontractor",
  "plural": false,
  "selections": (v25/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProcessingCertCardQuery",
    "selections": [
      {
        "alias": "certificates_to_verify_connection",
        "args": [
          (v1/*: any*/)
        ],
        "concreteType": "certificates_to_verifyConnection",
        "kind": "LinkedField",
        "name": "__ProcessingCertCard_certificates_to_verify_connection_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "certificates_to_verifyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "certificates_to_verify",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "image",
                    "kind": "LinkedField",
                    "name": "front_image",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "uploaded_by_user",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor_employee",
                        "kind": "LinkedField",
                        "name": "employee",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "employee_title",
                            "kind": "LinkedField",
                            "name": "employee_title",
                            "plural": false,
                            "selections": (v15/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "subcontractor_employee",
                        "kind": "LinkedField",
                        "name": "subcontractor_employee",
                        "plural": false,
                        "selections": [
                          (v16/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "subcontractor_employee_title",
                            "kind": "LinkedField",
                            "name": "employee_title",
                            "plural": false,
                            "selections": (v15/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "worker",
                        "kind": "LinkedField",
                        "name": "worker",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "worker_title",
                            "kind": "LinkedField",
                            "name": "worker_title",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "text_translation",
                                "kind": "LinkedField",
                                "name": "translation",
                                "plural": false,
                                "selections": (v14/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "image",
                    "kind": "LinkedField",
                    "name": "back_image",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": null
                  },
                  (v19/*: any*/)
                ],
                "storageKey": null
              },
              (v20/*: any*/)
            ],
            "storageKey": null
          },
          (v21/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProcessingCertCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v22/*: any*/),
        "concreteType": "certificates_to_verifyConnection",
        "kind": "LinkedField",
        "name": "certificates_to_verify_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "certificates_to_verifyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "certificates_to_verify",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v26/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "image",
                    "kind": "LinkedField",
                    "name": "front_image",
                    "plural": false,
                    "selections": (v27/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "uploaded_by_user",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v2/*: any*/),
                      (v23/*: any*/),
                      (v24/*: any*/),
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor_employee",
                        "kind": "LinkedField",
                        "name": "employee",
                        "plural": false,
                        "selections": [
                          (v26/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "employee_title",
                            "kind": "LinkedField",
                            "name": "employee_title",
                            "plural": false,
                            "selections": (v29/*: any*/),
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "subcontractor_employee",
                        "kind": "LinkedField",
                        "name": "subcontractor_employee",
                        "plural": false,
                        "selections": [
                          (v30/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "subcontractor_employee_title",
                            "kind": "LinkedField",
                            "name": "employee_title",
                            "plural": false,
                            "selections": (v29/*: any*/),
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "worker",
                        "kind": "LinkedField",
                        "name": "worker",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "worker_title",
                            "kind": "LinkedField",
                            "name": "worker_title",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "text_translation",
                                "kind": "LinkedField",
                                "name": "translation",
                                "plural": false,
                                "selections": (v28/*: any*/),
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v30/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "image",
                    "kind": "LinkedField",
                    "name": "back_image",
                    "plural": false,
                    "selections": (v27/*: any*/),
                    "storageKey": null
                  },
                  (v19/*: any*/)
                ],
                "storageKey": null
              },
              (v20/*: any*/)
            ],
            "storageKey": null
          },
          (v21/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v22/*: any*/),
        "filters": [
          "where"
        ],
        "handle": "connection",
        "key": "ProcessingCertCard_certificates_to_verify_connection",
        "kind": "LinkedHandle",
        "name": "certificates_to_verify_connection"
      }
    ]
  },
  "params": {
    "cacheID": "b3116befc111698f3d35a9a3fbc206b8",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "certificates_to_verify_connection"
          ]
        }
      ]
    },
    "name": "ProcessingCertCardQuery",
    "operationKind": "query",
    "text": "query ProcessingCertCardQuery(\n  $certsToVerifyWhere: certificates_to_verify_bool_exp!\n) {\n  certificates_to_verify_connection(where: $certsToVerifyWhere, first: 10000) {\n    edges {\n      node {\n        id\n        pk: id\n        worker_id\n        created_at\n        project {\n          name\n          general_contractor {\n            name\n            id\n          }\n          id\n        }\n        front_image_id\n        front_image {\n          url\n          id\n        }\n        uploaded_by_user {\n          name\n          id\n          pk: id\n          role\n          employee {\n            general_contractor {\n              name\n              id\n            }\n            employee_title {\n              name {\n                en\n                id\n              }\n              id\n            }\n            id\n          }\n          subcontractor_employee {\n            subcontractor {\n              name\n              id\n            }\n            employee_title {\n              name {\n                en\n                id\n              }\n              id\n            }\n            id\n          }\n          worker {\n            current_worker_role\n            worker_title {\n              translation {\n                en\n                id\n              }\n              id\n            }\n            subcontractor {\n              name\n              id\n            }\n            id\n          }\n        }\n        back_image_id\n        back_image {\n          url\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9574fef2f24c836d19b63d598a49ff8a";

export default node;
