/**
 * @generated SignedSource<<090a6ad67f53d0984134204a06c3ee60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { procore_subcontractor_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProcoreSubConfigModal_upsertProcoreSubcontractor_Mutation$variables = {
  objects: ReadonlyArray<procore_subcontractor_insert_input>;
};
export type ProcoreSubConfigModal_upsertProcoreSubcontractor_Mutation$data = {
  readonly insert_procore_subcontractor: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type ProcoreSubConfigModal_upsertProcoreSubcontractor_Mutation = {
  response: ProcoreSubConfigModal_upsertProcoreSubcontractor_Mutation$data;
  variables: ProcoreSubConfigModal_upsertProcoreSubcontractor_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "objects"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "procore_subcontractor_project_id_subcontractor_id_key",
          "update_columns": [
            "procore_subcontractor_id",
            "procore_trade_id"
          ]
        }
      }
    ],
    "concreteType": "procore_subcontractor_mutation_response",
    "kind": "LinkedField",
    "name": "insert_procore_subcontractor",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProcoreSubConfigModal_upsertProcoreSubcontractor_Mutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProcoreSubConfigModal_upsertProcoreSubcontractor_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c8b00533c8dca22a57da20d625f4bc21",
    "id": null,
    "metadata": {},
    "name": "ProcoreSubConfigModal_upsertProcoreSubcontractor_Mutation",
    "operationKind": "mutation",
    "text": "mutation ProcoreSubConfigModal_upsertProcoreSubcontractor_Mutation(\n  $objects: [procore_subcontractor_insert_input!]!\n) {\n  insert_procore_subcontractor(objects: $objects, on_conflict: {constraint: procore_subcontractor_project_id_subcontractor_id_key, update_columns: [procore_subcontractor_id, procore_trade_id]}) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "c997421da56e72554391c033b19161a3";

export default node;
