/**
 * @generated SignedSource<<13ffa43694e9f796ea9c8df6a5766c85>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { work_above_elevation_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateWorkAboveElevationMutation$variables = {
  objects: ReadonlyArray<work_above_elevation_insert_input>;
};
export type CreateWorkAboveElevationMutation$data = {
  readonly insert_work_above_elevation: {
    readonly returning: ReadonlyArray<{
      readonly completed_at: string | null | undefined;
      readonly created_at: string;
      readonly created_by_user: {
        readonly employee: {
          readonly employee_title: {
            readonly name: {
              readonly en: string;
            };
          } | null | undefined;
        } | null | undefined;
        readonly name: string;
      };
      readonly id: string;
      readonly name: {
        readonly en: string;
        readonly id: string;
      };
      readonly name_id: string;
      readonly pk: string;
      readonly type_id: string;
      readonly work_above_elevation_type: {
        readonly name: {
          readonly en: string;
        };
      };
      readonly work_above_floors: ReadonlyArray<{
        readonly completed_at: string | null | undefined;
        readonly has_netting: boolean;
        readonly id: string;
        readonly name: {
          readonly en: string;
          readonly id: string;
        };
        readonly name_id: string;
        readonly order: number;
        readonly pk: string;
      }>;
    }>;
  } | null | undefined;
};
export type CreateWorkAboveElevationMutation = {
  response: CreateWorkAboveElevationMutation$data;
  variables: CreateWorkAboveElevationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v4 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name_id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "text_translation",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v2/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type_id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "text_translation",
    "kind": "LinkedField",
    "name": "name",
    "plural": false,
    "selections": [
      (v6/*: any*/)
    ],
    "storageKey": null
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed_at",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "has_netting",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "text_translation",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v15 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v16 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v17 = [
  (v7/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateWorkAboveElevationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "work_above_elevation_mutation_response",
        "kind": "LinkedField",
        "name": "insert_work_above_elevation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "work_above_elevation",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "created_by_user",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor_employee",
                    "kind": "LinkedField",
                    "name": "employee",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "employee_title",
                        "kind": "LinkedField",
                        "name": "employee_title",
                        "plural": false,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "work_above_elevation_type",
                "kind": "LinkedField",
                "name": "work_above_elevation_type",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              },
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "work_above_floor",
                "kind": "LinkedField",
                "name": "work_above_floors",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v12/*: any*/),
                  (v5/*: any*/),
                  (v4/*: any*/),
                  (v11/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateWorkAboveElevationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "work_above_elevation_mutation_response",
        "kind": "LinkedField",
        "name": "insert_work_above_elevation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "work_above_elevation",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v5/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "created_by_user",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor_employee",
                    "kind": "LinkedField",
                    "name": "employee",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "employee_title",
                        "kind": "LinkedField",
                        "name": "employee_title",
                        "plural": false,
                        "selections": (v17/*: any*/),
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "work_above_elevation_type",
                "kind": "LinkedField",
                "name": "work_above_elevation_type",
                "plural": false,
                "selections": (v17/*: any*/),
                "storageKey": null
              },
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "work_above_floor",
                "kind": "LinkedField",
                "name": "work_above_floors",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v12/*: any*/),
                  (v5/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v11/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2920a460a51bfde04c76c0a507b971ac",
    "id": null,
    "metadata": {},
    "name": "CreateWorkAboveElevationMutation",
    "operationKind": "mutation",
    "text": "mutation CreateWorkAboveElevationMutation(\n  $objects: [work_above_elevation_insert_input!]!\n) {\n  insert_work_above_elevation(objects: $objects) {\n    returning {\n      id\n      created_at\n      pk: id\n      name_id\n      name {\n        en\n        id\n      }\n      type_id\n      created_by_user {\n        name\n        employee {\n          employee_title {\n            name {\n              en\n              id\n            }\n            id\n          }\n          id\n        }\n        id\n      }\n      work_above_elevation_type {\n        name {\n          en\n          id\n        }\n        id\n      }\n      completed_at\n      work_above_floors {\n        id\n        order\n        name_id\n        pk: id\n        completed_at\n        has_netting\n        name {\n          id\n          en\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4e4e2b1d271e4b199a126b3b3973a362";

export default node;
