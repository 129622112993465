/**
 * @generated SignedSource<<70d20bad5425e7853b42f0d9ade40af6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { safety_data_sheet_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddSdsFromCompanyLibraryModalMutation$variables = {
  objects: ReadonlyArray<safety_data_sheet_insert_input>;
};
export type AddSdsFromCompanyLibraryModalMutation$data = {
  readonly insert_safety_data_sheet: {
    readonly returning: ReadonlyArray<{
      readonly company_name: string;
      readonly company_safety_data_sheet_id: string | null | undefined;
      readonly created_at: string;
      readonly created_by_user: {
        readonly id: string;
        readonly name: string;
        readonly pk: string;
      };
      readonly file_type: string;
      readonly id: string;
      readonly issue_date: string | null | undefined;
      readonly parent_sds: {
        readonly project_id: string | null | undefined;
        readonly subcontractor_id: string | null | undefined;
      } | null | undefined;
      readonly pk: string;
      readonly product_name: string;
      readonly project: {
        readonly name: string;
      } | null | undefined;
      readonly project_id: string | null | undefined;
      readonly reference_number: string | null | undefined;
      readonly subcontractor: {
        readonly name: string;
      } | null | undefined;
      readonly subcontractor_id: string | null | undefined;
      readonly url: string;
    }>;
  } | null | undefined;
};
export type AddSdsFromCompanyLibraryModalMutation = {
  response: AddSdsFromCompanyLibraryModalMutation$data;
  variables: AddSdsFromCompanyLibraryModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "product_name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "company_name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = [
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subcontractor_id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reference_number",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issue_date",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "company_safety_data_sheet_id",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "file_type",
  "storageKey": null
},
v16 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v18 = [
  (v8/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddSdsFromCompanyLibraryModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "safety_data_sheet_mutation_response",
        "kind": "LinkedField",
        "name": "insert_safety_data_sheet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "safety_data_sheet",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "created_by_user",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor",
                "kind": "LinkedField",
                "name": "subcontractor",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "safety_data_sheet",
                "kind": "LinkedField",
                "name": "parent_sds",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddSdsFromCompanyLibraryModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "safety_data_sheet_mutation_response",
        "kind": "LinkedField",
        "name": "insert_safety_data_sheet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "safety_data_sheet",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": (v18/*: any*/),
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "created_by_user",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor",
                "kind": "LinkedField",
                "name": "subcontractor",
                "plural": false,
                "selections": (v18/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "safety_data_sheet",
                "kind": "LinkedField",
                "name": "parent_sds",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v10/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d85a06bf35d1497f3acf35ad3925fda3",
    "id": null,
    "metadata": {},
    "name": "AddSdsFromCompanyLibraryModalMutation",
    "operationKind": "mutation",
    "text": "mutation AddSdsFromCompanyLibraryModalMutation(\n  $objects: [safety_data_sheet_insert_input!]!\n) {\n  insert_safety_data_sheet(objects: $objects) {\n    returning {\n      id\n      pk: id\n      product_name\n      company_name\n      created_at\n      project_id\n      project {\n        name\n        id\n      }\n      subcontractor_id\n      reference_number\n      issue_date\n      url\n      company_safety_data_sheet_id\n      file_type\n      created_by_user {\n        id\n        pk: id\n        name\n      }\n      subcontractor {\n        name\n        id\n      }\n      parent_sds {\n        project_id\n        subcontractor_id\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d59419228a2e35fb48a4efde63a8e765";

export default node;
