/**
 * @generated SignedSource<<53cd35ee58f5989049dc080c471e4815>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type GCProjectCalendarSitedeliveryDeliveriesTimezoneQuery$variables = {
  where: project_bool_exp;
};
export type GCProjectCalendarSitedeliveryDeliveriesTimezoneQuery$data = {
  readonly project_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly timezone: string;
      };
    }>;
  };
};
export type GCProjectCalendarSitedeliveryDeliveriesTimezoneQuery = {
  response: GCProjectCalendarSitedeliveryDeliveriesTimezoneQuery$data;
  variables: GCProjectCalendarSitedeliveryDeliveriesTimezoneQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectCalendarSitedeliveryDeliveriesTimezoneQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "projectConnection",
        "kind": "LinkedField",
        "name": "project_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "projectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCProjectCalendarSitedeliveryDeliveriesTimezoneQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "projectConnection",
        "kind": "LinkedField",
        "name": "project_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "projectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ad427e64e6736abb50218f78f4fa77e1",
    "id": null,
    "metadata": {},
    "name": "GCProjectCalendarSitedeliveryDeliveriesTimezoneQuery",
    "operationKind": "query",
    "text": "query GCProjectCalendarSitedeliveryDeliveriesTimezoneQuery(\n  $where: project_bool_exp!\n) {\n  project_connection(where: $where) {\n    edges {\n      node {\n        timezone\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4d69ff663cb61ea7a9d69423039d782c";

export default node;
