/**
 * @generated SignedSource<<be56f6883d6a1ee3227c246c0de2a9df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { email_project_user_bool_exp, email_project_user_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NotifyUserSettings_Update_Email_Project_User_Mutation$variables = {
  deleteWhere: email_project_user_bool_exp;
  objects: ReadonlyArray<email_project_user_insert_input>;
};
export type NotifyUserSettings_Update_Email_Project_User_Mutation$data = {
  readonly delete_email_project_user: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly type: string | null | undefined;
      readonly user: {
        readonly name: string;
      };
      readonly user_id: string;
    }>;
  } | null | undefined;
  readonly insert_email_project_user: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly type: string | null | undefined;
      readonly user: {
        readonly name: string;
      };
      readonly user_id: string;
    }>;
  } | null | undefined;
};
export type NotifyUserSettings_Update_Email_Project_User_Mutation = {
  response: NotifyUserSettings_Update_Email_Project_User_Mutation$data;
  variables: NotifyUserSettings_Update_Email_Project_User_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deleteWhere"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "objects"
},
v2 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "deleteWhere"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "email_project_user",
    "kind": "LinkedField",
    "name": "returning",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v8 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  }
],
v9 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "email_project_user",
    "kind": "LinkedField",
    "name": "returning",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotifyUserSettings_Update_Email_Project_User_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "email_project_user_mutation_response",
        "kind": "LinkedField",
        "name": "delete_email_project_user",
        "plural": false,
        "selections": (v7/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "email_project_user_mutation_response",
        "kind": "LinkedField",
        "name": "insert_email_project_user",
        "plural": false,
        "selections": (v7/*: any*/),
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "NotifyUserSettings_Update_Email_Project_User_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "email_project_user_mutation_response",
        "kind": "LinkedField",
        "name": "delete_email_project_user",
        "plural": false,
        "selections": (v9/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "email_project_user_mutation_response",
        "kind": "LinkedField",
        "name": "insert_email_project_user",
        "plural": false,
        "selections": (v9/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f7189005e699dc9b0bd010cf1bee21f2",
    "id": null,
    "metadata": {},
    "name": "NotifyUserSettings_Update_Email_Project_User_Mutation",
    "operationKind": "mutation",
    "text": "mutation NotifyUserSettings_Update_Email_Project_User_Mutation(\n  $objects: [email_project_user_insert_input!]!\n  $deleteWhere: email_project_user_bool_exp!\n) {\n  delete_email_project_user(where: $deleteWhere) {\n    returning {\n      type\n      user_id\n      id\n      user {\n        name\n        id\n      }\n    }\n  }\n  insert_email_project_user(objects: $objects) {\n    returning {\n      type\n      user_id\n      id\n      user {\n        name\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8b31bd901378f0c4c71cf380645e0f7b";

export default node;
