/**
 * @generated SignedSource<<64cd7780b08d1aa8ce32c92b58e696c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { user_edit_history_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InsertUserNameEditHistoryMutation$variables = {
  input: ReadonlyArray<user_edit_history_insert_input>;
};
export type InsertUserNameEditHistoryMutation$data = {
  readonly insert_user_edit_history: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type InsertUserNameEditHistoryMutation = {
  response: InsertUserNameEditHistoryMutation$data;
  variables: InsertUserNameEditHistoryMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "input"
      }
    ],
    "concreteType": "user_edit_history_mutation_response",
    "kind": "LinkedField",
    "name": "insert_user_edit_history",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InsertUserNameEditHistoryMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InsertUserNameEditHistoryMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9a7988830a1d108987f0f13ae395bb02",
    "id": null,
    "metadata": {},
    "name": "InsertUserNameEditHistoryMutation",
    "operationKind": "mutation",
    "text": "mutation InsertUserNameEditHistoryMutation(\n  $input: [user_edit_history_insert_input!]!\n) {\n  insert_user_edit_history(objects: $input) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "d45c0abb8d32f8a118c3996b40cce145";

export default node;
