/**
 * @generated SignedSource<<144b157c3d7d08be08dddb263e07b38f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { inspection_instance_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InspectionResultSummaryTableTotalRefetchableQuery$variables = {
  where: inspection_instance_bool_exp;
};
export type InspectionResultSummaryTableTotalRefetchableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"InspectionResultSummaryTable_total">;
};
export type InspectionResultSummaryTableTotalRefetchableQuery = {
  response: InspectionResultSummaryTableTotalRefetchableQuery$data;
  variables: InspectionResultSummaryTableTotalRefetchableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InspectionResultSummaryTableTotalRefetchableQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "where",
            "variableName": "where"
          }
        ],
        "kind": "FragmentSpread",
        "name": "InspectionResultSummaryTable_total"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InspectionResultSummaryTableTotalRefetchableQuery",
    "selections": [
      {
        "alias": "allInspectionResultsConnection",
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "inspection_instance",
                "variableName": "where"
              }
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "inspection_resultConnection",
        "kind": "LinkedField",
        "name": "inspection_result_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "inspection_resultEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "inspection_result",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f59aba511172a44aca6a3f11afc78617",
    "id": null,
    "metadata": {},
    "name": "InspectionResultSummaryTableTotalRefetchableQuery",
    "operationKind": "query",
    "text": "query InspectionResultSummaryTableTotalRefetchableQuery(\n  $where: inspection_instance_bool_exp!\n) {\n  ...InspectionResultSummaryTable_total_3FC4Qo\n}\n\nfragment InspectionResultSummaryTable_total_3FC4Qo on query_root {\n  allInspectionResultsConnection: inspection_result_connection(where: {inspection_instance: $where}) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3bf0a925c0ec28176079e6ae4a77cadf";

export default node;
