import React, { useState } from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes.d";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import TaskSelectionViewForPtpSubmission from "src/domain-features/sitesafety/job-hazard-analysis/components/tables/TaskSelectionViewForPtpSubmission";
import TaskViewQrPtpSubmission from "src/domain-features/sitesafety/job-hazard-analysis/components/task-view/TaskViewQrPtpSubmission";
import AddTaskModal from "src/domain-features/sitesafety/job-hazard-analysis/components/modals/AddTaskModal";
import CustomButton from "src/common/components/general/button/Button";
import { useSuspenseQuery } from "@apollo/client";
import {
  GetPtpQrTaskSelectionQuery,
  GetPtpQrTaskSelectionQueryVariables,
  GetPtpQrTaskSelectionDocument,
} from "src/common/types/generated/apollo/graphQLTypes";
import { Input } from "antd";
import usePtpQr from "../../../utils/ptpQrHook";
import dayjs from "dayjs";
import {
  useCurrentLangStrings,
  useCurrentLanguage,
} from "src/utility-features/i18n/context/languageHooks";
import { useGetCompletionAuditV4Query } from "src/common/types/generated/apollo/graphQLTypes";
import { PtpQrTaskType } from "src/domain-features/sitesafety/entry-routes/qr-project-reports/routes/ptp/types/PtpQrUserTypes";
import { TextTranslationFragFragment } from "src/common/types/generated/apollo/graphQLTypes";

export interface TaskSelectionForPtpSubmissionProps
  extends SiteOrientationStepProps {}

const TaskSelectionForPtpSubmission: React.FC<
  TaskSelectionForPtpSubmissionProps
> = (props) => {
  const langStrings = useCurrentLangStrings();
  const lang = useCurrentLanguage();
  const { ptpQrUser, setPtpQrUser, projectId } = usePtpQr();
  const projectWorkerId = ptpQrUser.userInfo?.projectWorkerId;
  const oneMonthBefore = dayjs().subtract(1, "month").startOf("d");
  if (!projectWorkerId) {
    throw new Error("project worker id not found");
  }

  const omitKeys = (
    obj: TextTranslationFragFragment,
  ): Omit<TextTranslationFragFragment, "__typename" | "id" | "clientText"> => {
    const keys: (keyof TextTranslationFragFragment)[] = [
      "__typename",
      "id",
      "clientText",
    ];
    const result = { ...obj };
    keys.forEach((key) => delete result[key]);
    return result;
  };

  const taskLang =
    lang === "fr" || lang === "zh_hans" || lang === "it" ? "en" : lang;

  const { data } = useSuspenseQuery<
    GetPtpQrTaskSelectionQuery,
    GetPtpQrTaskSelectionQueryVariables
  >(GetPtpQrTaskSelectionDocument, {
    variables: {
      projectId,
      projWorkerId: projectWorkerId,
      taskWhere: {
        _or: [
          { report_id: { _is_null: true } },
          {
            original_report_task_id: { _is_null: true },
            report: {
              submitter_project_worker_id: { _eq: projectWorkerId },
            },
          },
        ],
        deleted_at: { _is_null: true },
        request_state: { _is_null: true },
        is_pending_acceptance: { _eq: false },
        project_id: { _eq: projectId },
        subcontractor_id: { _eq: ptpQrUser.userInfo?.company },
      },

      reportWhere: {
        submitter_project_worker_id: {
          _eq: projectWorkerId,
        },
        created_at: {
          _gte: oneMonthBefore.toISOString(),
        },
      },

      uploadedTBTWhere: {
        due_time: {
          _lte: dayjs().endOf("d").toISOString(),
          _gte: oneMonthBefore.toISOString(),
        },
        archived_at: { _is_null: true },
        _not: {
          toolbox_talks: {
            project_id: { _eq: projectId },
            _or: [
              {
                toolbox_talk_project_workers: {
                  project_worker: {
                    worker_id: { _eq: ptpQrUser.userInfo?.id },
                  },
                },
              },
              {
                submitter_project_worker: {
                  worker_id: { _eq: ptpQrUser.userInfo?.id },
                },
              },
              {
                led_by_project_worker: {
                  worker_id: { _eq: ptpQrUser.userInfo?.id },
                },
              },
            ],
          },
        },
        _or: [
          {
            general_contractor: { projects: { id: { _eq: projectId } } },
            uploaded_toolbox_talk_projects: { project_id: { _eq: projectId } },
            _or: [
              { assign_to: { _eq: "all-subcontractors" } },
              {
                uploaded_toolbox_talk_subcontractors: {
                  subcontractor_id: { _eq: ptpQrUser.userInfo?.company },
                },
              },
            ],
          },
          {
            subcontractor_id: { _eq: ptpQrUser.userInfo?.company },
            _or: [
              { assign_to: { _eq: "all-projects" } },
              {
                uploaded_toolbox_talk_projects: {
                  project_id: { _eq: projectId },
                },
              },
            ],
          },
        ],
      },
    },
  });

  const [searchPrompt, setSearchPrompt] = useState<string>("");
  const selectedTasks = ptpQrUser.selectedTasks;
  const initialTaskMap = data.task.reduce((acc, task) => {
    acc[task.id] = {
      id: task.id,
      images:
        task.task_creation_request?.attached_files?.map((d) => ({
          id: d.id,
          url: d.url,
        })) ?? [],
      archivedAt: task.archived_at ?? null,
      description: {
        edited: false,
        translation: omitKeys(task.description),
      },
      acceptedAt: task.accepted_at_from_creation_request ?? null,
      acceptedByUid: task.accepted_by_uid_from_creation_request ?? null,
      creationRequestId: task.task_creation_request?.id ?? null,
      documentId: task.document_id ?? null,
      showType: "text",
      newlyAdded: false,
      permits:
        task.task_permit_types?.map((p) => ({
          id: p.permit_type.id,
          description: p.permit_type.name[taskLang],
        })) || [],
      ppes:
        task.task_ppe_types?.map((p) => ({
          id: p.ppe_type.id,
          description: p.ppe_type.name[taskLang],
        })) || [],
      steps:
        task.task_steps?.map((step) => ({
          id: step.id,
          description: {
            edited: false,
            translation: omitKeys(step.description),
          },
          hazards:
            step.task_step_hazards?.map((h) => ({
              id: h.id,
              description: {
                edited: false,
                translation: omitKeys(h.description),
              },
              control: {
                edited: false,
                translation: omitKeys(h.control),
              },
              ecms:
                h.task_hazard_ecm_types?.map((e) => ({
                  id: e.ecm_type.id,
                  description: e.ecm_type.name[taskLang],
                })) ?? [],
            })) ?? [],
        })) ?? [],
    };
    return acc;
  }, {} as { [id: string]: PtpQrTaskType });

  const [taskMap, setTaskMap] = useState<{ [id: string]: PtpQrTaskType }>(
    initialTaskMap,
  );
  const [task, setTask] = useState<PtpQrTaskType>();
  const [addTaskModalVisible, setAddTaskModalVisible] = useState(false);

  const { data: completionAudit } = useGetCompletionAuditV4Query({
    variables: {
      input: {
        startDate: oneMonthBefore.toISOString(),
        endDate: dayjs().tz(ptpQrUser.timezone).endOf("day").toISOString(),
        projectId: projectId,
        subcontractorId: ptpQrUser.userInfo?.company,
        timezone: ptpQrUser.timezone,
      },
    },
  });

  const subOnsiteMap: { [date: string]: boolean } = {};
  completionAudit?.getCompletionAuditV4.forEach((p) => {
    p.dailyCompletion.forEach(
      (result) =>
        (subOnsiteMap[dayjs(result.date).format("YYYY-MM-DD")] =
          result.onsite_required || result.is_onsite),
    );
  });

  const pendingTbts = data.uploaded_toolbox_talk
    .filter((tbt) => {
      const tbtDueDate = dayjs(tbt.due_time).startOf("day");
      const today = dayjs().startOf("day");
      if (!subOnsiteMap || tbtDueDate.isSameOrAfter(today)) {
        return true;
      }
      if (tbtDueDate.isSame(today)) {
        return true;
      }
      const weekStart = tbtDueDate.startOf("isoWeek");
      const subOnsiteDays = [0, 1, 2, 3, 4].reduce((count, i) => {
        const date = weekStart.clone().add(i, "day").format("YYYY-MM-DD");
        return subOnsiteMap[date] ? count + 1 : count;
      }, 0);

      return (
        subOnsiteDays >= 3 ||
        (weekStart.isoWeek() === tbtDueDate.isoWeek() && subOnsiteDays >= 2)
      );
    })
    .splice(0, 2);

  const ppeCategoryData = data.ppe_category.map((category) => ({
    id: category.id,
    description: category.name[taskLang],
    ppes: category.ppe_types.map((ppe) => ({
      id: ppe.id,
      description: ppe.name[taskLang],
    })),
  }));

  const onUpdateTask = () => {
    if (task) {
      setPtpQrUser((prev) => ({
        ...prev,
        selectedTasks: { ...prev.selectedTasks, [task.id]: task },
      }));
    }
  };

  const getSortedTasks = () => {
    const recentReportTaskIds = new Set(
      data.report.flatMap((report) =>
        report.tasks.map((t) => t.original_report_task_id),
      ),
    );
    return Object.values(taskMap).sort((a, b) => {
      const aInRecentReports = recentReportTaskIds.has(a.id);
      const bInRecentReports = recentReportTaskIds.has(b.id);
      if (aInRecentReports !== bInRecentReports) {
        return aInRecentReports ? -1 : 1;
      }
      const aIsArchived = !!a.archivedAt;
      const bIsArchived = !!b.archivedAt;
      if (aIsArchived !== bIsArchived) {
        return aIsArchived ? 1 : -1;
      }
      return 0;
    });
  };

  const sortedTasks = getSortedTasks();

  const handleCreateTask = async (taskVal: any) => {
    setAddTaskModalVisible(false);
    const newTask = {
      archivedAt: null,
      description: {
        edited: true as const,
        editedValue: taskVal.description,
      },
      acceptedAt: null,
      acceptedByUid: null,
      creationRequestId: null,
      documentId: null,
      showType: "text" as const,
      id: taskVal.id,
      newlyAdded: true,
      permits: [],
      ppes: [],
      steps: [],
      images: [],
    };

    setTaskMap((prev) => ({ ...prev, [taskVal.id]: newTask }));
    setTask(newTask);
  };

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={() => {
        if (task) {
          onUpdateTask();
          setTaskMap((prev) => ({ ...prev, [task.id]: task }));
          setTask(undefined);
        } else {
          props.onNext();
          setPtpQrUser((prev) => ({
            ...prev,
            pendingTbts: pendingTbts.map((tbt) => ({
              content: tbt.content ? tbt.content[taskLang] : "",
              dueTime: tbt.due_time ?? undefined,
              id: tbt.id,
              images: tbt.images.map((img) => ({ url: img.url })),
              name: tbt.name,
            })),
          }));
        }
      }}
      onBackButtonClick={task ? () => setTask(undefined) : props.onBack}
      nextButtonText={task ? "Save" : "Next"}
      nextButtonDisabled={!task && Object.keys(selectedTasks).length === 0}
    >
      {task ? (
        <TaskViewQrPtpSubmission
          task={task}
          setTask={setTask}
          dataForEcm={data.ecm_type.map((ecm) => ({
            id: ecm.id,
            description: ecm.name[taskLang],
          }))}
          dataForPermit={data.permit_type.map((permit) => ({
            id: permit.id,
            description: permit.name[taskLang],
          }))}
          dataForPpe={ppeCategoryData}
          isTurner={false}
          key={task.id}
        />
      ) : (
        <SiteFeatureStepsInputLayout
          headline={langStrings.strings.selectTasksYouArePerforming}
        >
          <AddTaskModal
            visible={addTaskModalVisible}
            onCancel={() => setAddTaskModalVisible(false)}
            onCreate={handleCreateTask}
          />
          <CustomButton
            label="Add a new JHA"
            tiny
            onClick={() => setAddTaskModalVisible(true)}
          />
          <Input
            placeholder={`Search JHAs`}
            value={searchPrompt}
            onChange={(e) => setSearchPrompt(e.target.value)}
          />

          <TaskSelectionViewForPtpSubmission
            list={sortedTasks.map((task) => {
              return {
                id: task.id,
                name:
                  task.description.edited === true
                    ? task.description.editedValue
                    : task.description.translation[taskLang],
                stepsCount: task.steps.length,
                ppeCount: task.ppes.length,
                permitCount: task.permits.length,
                hazardsCount: task.steps.reduce(
                  (acc, step) => (acc += step.hazards.length),
                  0,
                ),
              };
            })}
            listTitle="Select JHAs"
            onSelectToggle={(id) => {
              setPtpQrUser((prev) => {
                const newSelectedTasks = selectedTasks[id]
                  ? Object.fromEntries(
                      Object.entries(selectedTasks).filter(
                        ([key]) => key !== id,
                      ),
                    )
                  : {
                      ...prev.selectedTasks,
                      [id]: taskMap[id],
                    };

                return {
                  ...prev,
                  selectedTasks: newSelectedTasks,
                };
              });
            }}
            onSelectTaskForEdit={(id) => {
              setTask(taskMap[id]);
            }}
            searchPrompt={searchPrompt}
          />
        </SiteFeatureStepsInputLayout>
      )}
    </SiteFeatureStepLayout>
  );
};

export default TaskSelectionForPtpSubmission;
