/**
 * @generated SignedSource<<b06fb4210d3cd782279911ff4d926820>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { safety_data_sheet_bool_exp, safety_data_sheet_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SubProjectMobilizationStepSds_UpdateSdsMutation$variables = {
  _set?: safety_data_sheet_set_input | null | undefined;
  where: safety_data_sheet_bool_exp;
};
export type SubProjectMobilizationStepSds_UpdateSdsMutation$data = {
  readonly update_safety_data_sheet: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type SubProjectMobilizationStepSds_UpdateSdsMutation = {
  response: SubProjectMobilizationStepSds_UpdateSdsMutation$data;
  variables: SubProjectMobilizationStepSds_UpdateSdsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "_set"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "safety_data_sheet_mutation_response",
    "kind": "LinkedField",
    "name": "update_safety_data_sheet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubProjectMobilizationStepSds_UpdateSdsMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubProjectMobilizationStepSds_UpdateSdsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6a8492d87c3d5d9a134a6cdebf63006e",
    "id": null,
    "metadata": {},
    "name": "SubProjectMobilizationStepSds_UpdateSdsMutation",
    "operationKind": "mutation",
    "text": "mutation SubProjectMobilizationStepSds_UpdateSdsMutation(\n  $_set: safety_data_sheet_set_input\n  $where: safety_data_sheet_bool_exp!\n) {\n  update_safety_data_sheet(_set: $_set, where: $where) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "e0181c7c3916152a7033317918e054ac";

export default node;
