/**
 * @generated SignedSource<<40b0b49dda143624385cf86cbd497e50>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { daily_safety_report_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddSafetyReportModalInsertSafetyReportMutation$variables = {
  object: daily_safety_report_insert_input;
};
export type AddSafetyReportModalInsertSafetyReportMutation$data = {
  readonly insert_daily_safety_report_one: {
    readonly created_at: string;
    readonly created_by_user: {
      readonly id: string;
      readonly name: string;
      readonly pk: string;
    };
    readonly daily_safety_report_comments: ReadonlyArray<{
      readonly id: string;
      readonly pk: string;
      readonly text_translation: {
        readonly en: string;
      };
    }>;
    readonly daily_safety_report_tags: ReadonlyArray<{
      readonly id: string;
      readonly pk: string;
      readonly tag: {
        readonly color_hex: string;
        readonly id: string;
        readonly name: {
          readonly clientText: string;
          readonly en: string;
          readonly es: string;
          readonly id: string;
          readonly original: string;
          readonly pk: string;
          readonly pt: string;
        };
        readonly pk: string;
      };
    }>;
    readonly id: string;
    readonly images: ReadonlyArray<{
      readonly blurhash: string | null | undefined;
      readonly description: string | null | undefined;
      readonly id: string;
      readonly lg_url: string | null | undefined;
      readonly md_url: string | null | undefined;
      readonly pk: string;
      readonly sm_url: string | null | undefined;
      readonly sort_index: number | null | undefined;
      readonly url: string;
    }>;
    readonly pk: string;
    readonly subcontractor: {
      readonly name: string;
      readonly pk: string;
    } | null | undefined;
  } | null | undefined;
};
export type AddSafetyReportModalInsertSafetyReportMutation = {
  response: AddSafetyReportModalInsertSafetyReportMutation$data;
  variables: AddSafetyReportModalInsertSafetyReportMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "object",
    "variableName": "object"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "sort_index": "asc"
    }
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sm_url",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "md_url",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lg_url",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blurhash",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sort_index",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color_hex",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "es",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pt",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "original",
  "storageKey": null
},
v19 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v20 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddSafetyReportModalInsertSafetyReportMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "daily_safety_report",
        "kind": "LinkedField",
        "name": "insert_daily_safety_report_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "created_by_user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "subcontractor",
            "kind": "LinkedField",
            "name": "subcontractor",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "image",
            "kind": "LinkedField",
            "name": "images",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": "images(order_by:{\"sort_index\":\"asc\"})"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "daily_safety_report_tag",
            "kind": "LinkedField",
            "name": "daily_safety_report_tags",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "tag",
                "kind": "LinkedField",
                "name": "tag",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v14/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "name",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": "clientText",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__original_clientText",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "daily_safety_report_comment",
            "kind": "LinkedField",
            "name": "daily_safety_report_comments",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "text_translation",
                "kind": "LinkedField",
                "name": "text_translation",
                "plural": false,
                "selections": [
                  (v15/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddSafetyReportModalInsertSafetyReportMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "daily_safety_report",
        "kind": "LinkedField",
        "name": "insert_daily_safety_report_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "created_by_user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "subcontractor",
            "kind": "LinkedField",
            "name": "subcontractor",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "image",
            "kind": "LinkedField",
            "name": "images",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/)
            ],
            "storageKey": "images(order_by:{\"sort_index\":\"asc\"})"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "daily_safety_report_tag",
            "kind": "LinkedField",
            "name": "daily_safety_report_tags",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "tag",
                "kind": "LinkedField",
                "name": "tag",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v14/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "name",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      {
                        "alias": "clientText",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "original",
                        "storageKey": null
                      },
                      {
                        "alias": "clientText",
                        "args": null,
                        "filters": null,
                        "handle": "clientText",
                        "key": "",
                        "kind": "ScalarHandle",
                        "name": "original"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "daily_safety_report_comment",
            "kind": "LinkedField",
            "name": "daily_safety_report_comments",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "text_translation",
                "kind": "LinkedField",
                "name": "text_translation",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c34bfab1cc6ce61c455613216c6227a5",
    "id": null,
    "metadata": {},
    "name": "AddSafetyReportModalInsertSafetyReportMutation",
    "operationKind": "mutation",
    "text": "mutation AddSafetyReportModalInsertSafetyReportMutation(\n  $object: daily_safety_report_insert_input!\n) {\n  insert_daily_safety_report_one(object: $object) {\n    id\n    pk: id\n    created_at\n    created_by_user {\n      id\n      name\n      pk: id\n    }\n    subcontractor {\n      name\n      pk: id\n      id\n    }\n    images(order_by: {sort_index: asc}) {\n      id\n      url\n      sm_url\n      md_url\n      lg_url\n      blurhash\n      sort_index\n      description\n      pk: id\n    }\n    daily_safety_report_tags {\n      id\n      pk: id\n      tag {\n        id\n        color_hex\n        pk: id\n        name {\n          id\n          en\n          es\n          pt\n          original\n          pk: id\n          clientText: original\n        }\n      }\n    }\n    daily_safety_report_comments {\n      id\n      pk: id\n      text_translation {\n        en\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "430f2ddff4709b8722e5801f330dfbee";

export default node;
