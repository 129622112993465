/**
 * @generated SignedSource<<2b1e8153e86fd402349012afa262cce0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { order_by, toolbox_talk_bool_exp, toolbox_talk_order_by } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ToolboxTalkTableRefetchableQuery$variables = {
  after?: string | null | undefined;
  first: number;
  includeProject: boolean;
  includeSub: boolean;
  order_by: ReadonlyArray<toolbox_talk_order_by>;
  where: toolbox_talk_bool_exp;
};
export type ToolboxTalkTableRefetchableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ToolboxTalkTable_toolbox_talks">;
};
export type ToolboxTalkTableRefetchableQuery = {
  response: ToolboxTalkTableRefetchableQuery$data;
  variables: ToolboxTalkTableRefetchableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "includeProject"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "includeSub"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order_by"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "order_by",
  "variableName": "order_by"
},
v4 = {
  "kind": "Variable",
  "name": "where",
  "variableName": "where"
},
v5 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  (v8/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ToolboxTalkTableRefetchableQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "Variable",
            "name": "includeProject",
            "variableName": "includeProject"
          },
          {
            "kind": "Variable",
            "name": "includeSub",
            "variableName": "includeSub"
          },
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ToolboxTalkTable_toolbox_talks"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ToolboxTalkTableRefetchableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "toolbox_talkConnection",
        "kind": "LinkedField",
        "name": "toolbox_talk_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "toolbox_talkEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "toolbox_talk",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created_at",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "description",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "en",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "es",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "pt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "original",
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": "clientText",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "original",
                        "storageKey": null
                      },
                      {
                        "alias": "clientText",
                        "args": null,
                        "filters": null,
                        "handle": "clientText",
                        "key": "",
                        "kind": "ScalarHandle",
                        "name": "original"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "condition": "includeProject",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "condition": "includeSub",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "subcontractor",
                        "kind": "LinkedField",
                        "name": "subcontractor",
                        "plural": false,
                        "selections": (v9/*: any*/),
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "toolbox_talk_project_worker_aggregate",
                    "kind": "LinkedField",
                    "name": "toolbox_talk_project_workers_aggregate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "toolbox_talk_project_worker_aggregate_fields",
                        "kind": "LinkedField",
                        "name": "aggregate",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "count",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "filters": [
          "where",
          "order_by"
        ],
        "handle": "connection",
        "key": "ToolboxTalkTable_toolbox_talk_connection",
        "kind": "LinkedHandle",
        "name": "toolbox_talk_connection"
      }
    ]
  },
  "params": {
    "cacheID": "c76067a52be7ebf73d8c26da9d02a0df",
    "id": null,
    "metadata": {},
    "name": "ToolboxTalkTableRefetchableQuery",
    "operationKind": "query",
    "text": "query ToolboxTalkTableRefetchableQuery(\n  $after: String\n  $first: Int!\n  $includeProject: Boolean!\n  $includeSub: Boolean!\n  $order_by: [toolbox_talk_order_by!]!\n  $where: toolbox_talk_bool_exp!\n) {\n  ...ToolboxTalkTable_toolbox_talks_1lAmFF\n}\n\nfragment ToolboxTalkTable_toolbox_talks_1lAmFF on query_root {\n  toolbox_talk_connection(first: $first, after: $after, where: $where, order_by: $order_by) {\n    edges {\n      node {\n        pk: id\n        created_at\n        description {\n          id\n          en\n          es\n          pt\n          original\n          pk: id\n          clientText: original\n        }\n        project @include(if: $includeProject) {\n          name\n          id\n        }\n        subcontractor @include(if: $includeSub) {\n          name\n          id\n        }\n        toolbox_talk_project_workers_aggregate {\n          aggregate {\n            count\n          }\n        }\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b81e1050460de4b8a596a901834ae7f6";

export default node;
