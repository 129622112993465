/**
 * @generated SignedSource<<4e9d7947dbba2c3f1b7b55a86f78d554>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_worker_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SCProjectWorkersMutation$variables = {
  objects: ReadonlyArray<project_worker_insert_input>;
};
export type SCProjectWorkersMutation$data = {
  readonly insert_project_worker: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type SCProjectWorkersMutation = {
  response: SCProjectWorkersMutation$data;
  variables: SCProjectWorkersMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "objects"
      }
    ],
    "concreteType": "project_worker_mutation_response",
    "kind": "LinkedField",
    "name": "insert_project_worker",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SCProjectWorkersMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SCProjectWorkersMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d56fec1896f8a1d94ab2ce6f3fc546a3",
    "id": null,
    "metadata": {},
    "name": "SCProjectWorkersMutation",
    "operationKind": "mutation",
    "text": "mutation SCProjectWorkersMutation(\n  $objects: [project_worker_insert_input!]!\n) {\n  insert_project_worker(objects: $objects) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "91b334dac5ecdd345ee657257dbc2c1c";

export default node;
