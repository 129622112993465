/**
 * @generated SignedSource<<3265d3ff58cb1d7ba9e0315746f2e4c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { worker_drug_test_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkerDrugTestTableTotalRefetchableQuery$variables = {
  where: worker_drug_test_bool_exp;
};
export type WorkerDrugTestTableTotalRefetchableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"WorkerDrugTestTable_total">;
};
export type WorkerDrugTestTableTotalRefetchableQuery = {
  response: WorkerDrugTestTableTotalRefetchableQuery$data;
  variables: WorkerDrugTestTableTotalRefetchableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkerDrugTestTableTotalRefetchableQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "WorkerDrugTestTable_total"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkerDrugTestTableTotalRefetchableQuery",
    "selections": [
      {
        "alias": "allDrugTestsConnetion",
        "args": (v1/*: any*/),
        "concreteType": "worker_drug_testConnection",
        "kind": "LinkedField",
        "name": "worker_drug_test_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "worker_drug_testEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "worker_drug_test",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "26c46ef1437db7865f375cfd6ef0d577",
    "id": null,
    "metadata": {},
    "name": "WorkerDrugTestTableTotalRefetchableQuery",
    "operationKind": "query",
    "text": "query WorkerDrugTestTableTotalRefetchableQuery(\n  $where: worker_drug_test_bool_exp!\n) {\n  ...WorkerDrugTestTable_total_3FC4Qo\n}\n\nfragment WorkerDrugTestTable_total_3FC4Qo on query_root {\n  allDrugTestsConnetion: worker_drug_test_connection(where: $where) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a4634209eba3629f16e007214b60b157";

export default node;
