/**
 * @generated SignedSource<<edd74e8fd0eb4109ec4ed2137659574a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { procore_project_data_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProcoreProjectConfigModal_upsertProcorePorjectData_Mutation$variables = {
  object: procore_project_data_insert_input;
};
export type ProcoreProjectConfigModal_upsertProcorePorjectData_Mutation$data = {
  readonly insert_procore_project_data_one: {
    readonly id: string;
    readonly pk: string;
    readonly procore_company_id: string | null | undefined;
    readonly procore_company_name: string | null | undefined;
    readonly procore_project_id: string | null | undefined;
    readonly procore_project_name: string | null | undefined;
    readonly project_id: string;
    readonly upload_daily_reports_enabled: boolean;
    readonly upload_daily_reports_folder_id: string | null | undefined;
    readonly upload_permits_enabled: boolean;
    readonly upload_permits_folder_id: string | null | undefined;
    readonly upload_safety_reports_enabled: boolean;
    readonly upload_safety_reports_folder_id: string | null | undefined;
    readonly upload_toolbox_talks_enabled: boolean;
    readonly upload_toolbox_talks_folder_id: string | null | undefined;
    readonly upload_worker_orientation_transcript_enabled: boolean;
    readonly upload_worker_orientation_transcript_folder_id: string | null | undefined;
  } | null | undefined;
};
export type ProcoreProjectConfigModal_upsertProcorePorjectData_Mutation = {
  response: ProcoreProjectConfigModal_upsertProcorePorjectData_Mutation$data;
  variables: ProcoreProjectConfigModal_upsertProcorePorjectData_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "object",
    "variableName": "object"
  },
  {
    "kind": "Literal",
    "name": "on_conflict",
    "value": {
      "constraint": "procore_project_data_pkey",
      "update_columns": [
        "procore_project_id",
        "procore_project_name",
        "procore_company_id",
        "procore_company_name",
        "upload_permits_enabled",
        "upload_permits_folder_id",
        "upload_daily_reports_enabled",
        "upload_daily_reports_folder_id",
        "upload_toolbox_talks_enabled",
        "upload_toolbox_talks_folder_id",
        "upload_safety_reports_enabled",
        "upload_safety_reports_folder_id",
        "upload_worker_orientation_transcript_enabled",
        "upload_worker_orientation_transcript_folder_id"
      ]
    }
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "procore_project_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "procore_project_name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "procore_company_id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "procore_company_name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_permits_enabled",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_permits_folder_id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_daily_reports_enabled",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_daily_reports_folder_id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_toolbox_talks_enabled",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_toolbox_talks_folder_id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_safety_reports_enabled",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_safety_reports_folder_id",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_worker_orientation_transcript_enabled",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "upload_worker_orientation_transcript_folder_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProcoreProjectConfigModal_upsertProcorePorjectData_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "procore_project_data",
        "kind": "LinkedField",
        "name": "insert_procore_project_data_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "__id_pk",
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProcoreProjectConfigModal_upsertProcorePorjectData_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "procore_project_data",
        "kind": "LinkedField",
        "name": "insert_procore_project_data_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": "pk",
            "args": null,
            "filters": null,
            "handle": "pk",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a01d0a6c667155bf89c7013e32f62884",
    "id": null,
    "metadata": {},
    "name": "ProcoreProjectConfigModal_upsertProcorePorjectData_Mutation",
    "operationKind": "mutation",
    "text": "mutation ProcoreProjectConfigModal_upsertProcorePorjectData_Mutation(\n  $object: procore_project_data_insert_input!\n) {\n  insert_procore_project_data_one(object: $object, on_conflict: {constraint: procore_project_data_pkey, update_columns: [procore_project_id, procore_project_name, procore_company_id, procore_company_name, upload_permits_enabled, upload_permits_folder_id, upload_daily_reports_enabled, upload_daily_reports_folder_id, upload_toolbox_talks_enabled, upload_toolbox_talks_folder_id, upload_safety_reports_enabled, upload_safety_reports_folder_id, upload_worker_orientation_transcript_enabled, upload_worker_orientation_transcript_folder_id]}) {\n    id\n    pk: id\n    project_id\n    procore_project_id\n    procore_project_name\n    procore_company_id\n    procore_company_name\n    upload_permits_enabled\n    upload_permits_folder_id\n    upload_daily_reports_enabled\n    upload_daily_reports_folder_id\n    upload_toolbox_talks_enabled\n    upload_toolbox_talks_folder_id\n    upload_safety_reports_enabled\n    upload_safety_reports_folder_id\n    upload_worker_orientation_transcript_enabled\n    upload_worker_orientation_transcript_folder_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "eeba2bd1db366b173f6555a6e9ce3906";

export default node;
