/**
 * @generated SignedSource<<88cc3a10a2d2f26009ed941ebe6a1967>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_alternate_certification_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SiteOrientationSettingAlternateCerts_Insert_Alternate_Certificates_Mutation$variables = {
  objects: ReadonlyArray<project_alternate_certification_insert_input>;
};
export type SiteOrientationSettingAlternateCerts_Insert_Alternate_Certificates_Mutation$data = {
  readonly insert_project_alternate_certification: {
    readonly returning: ReadonlyArray<{
      readonly alternate_certification: {
        readonly name: string;
      } | null | undefined;
      readonly certification: {
        readonly name: string;
      } | null | undefined;
      readonly id: string;
    }>;
  } | null | undefined;
};
export type SiteOrientationSettingAlternateCerts_Insert_Alternate_Certificates_Mutation = {
  response: SiteOrientationSettingAlternateCerts_Insert_Alternate_Certificates_Mutation$data;
  variables: SiteOrientationSettingAlternateCerts_Insert_Alternate_Certificates_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = [
  (v3/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteOrientationSettingAlternateCerts_Insert_Alternate_Certificates_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_alternate_certification_mutation_response",
        "kind": "LinkedField",
        "name": "insert_project_alternate_certification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_alternate_certification",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "certification",
                "kind": "LinkedField",
                "name": "certification",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "certification",
                "kind": "LinkedField",
                "name": "alternate_certification",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SiteOrientationSettingAlternateCerts_Insert_Alternate_Certificates_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_alternate_certification_mutation_response",
        "kind": "LinkedField",
        "name": "insert_project_alternate_certification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_alternate_certification",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "certification",
                "kind": "LinkedField",
                "name": "certification",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "certification",
                "kind": "LinkedField",
                "name": "alternate_certification",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d86d870182b77af821bb3929eac9c32a",
    "id": null,
    "metadata": {},
    "name": "SiteOrientationSettingAlternateCerts_Insert_Alternate_Certificates_Mutation",
    "operationKind": "mutation",
    "text": "mutation SiteOrientationSettingAlternateCerts_Insert_Alternate_Certificates_Mutation(\n  $objects: [project_alternate_certification_insert_input!]!\n) {\n  insert_project_alternate_certification(objects: $objects) {\n    returning {\n      id\n      certification {\n        name\n        id\n      }\n      alternate_certification {\n        name\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d87f4c961e7756d40497e3670d02315b";

export default node;
