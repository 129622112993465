/**
 * @generated SignedSource<<fd77bcf7105b1db4f6a761a87242b6d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { certificates_to_verify_insert_input, image_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SiteOrientationStepsUIProfilePhotoMutation$variables = {
  certsToVerifyObjects: ReadonlyArray<certificates_to_verify_insert_input>;
  imageObj: image_insert_input;
  imgId: string;
  userId: string;
};
export type SiteOrientationStepsUIProfilePhotoMutation$data = {
  readonly insert_certificates_to_verify: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly insert_image_one: {
    readonly id: string;
  } | null | undefined;
  readonly update_user_by_pk: {
    readonly id: string;
    readonly profile_picture_id: string | null | undefined;
  } | null | undefined;
};
export type SiteOrientationStepsUIProfilePhotoMutation = {
  response: SiteOrientationStepsUIProfilePhotoMutation$data;
  variables: SiteOrientationStepsUIProfilePhotoMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "certsToVerifyObjects"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "imageObj"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "imgId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "object",
        "variableName": "imageObj"
      }
    ],
    "concreteType": "image",
    "kind": "LinkedField",
    "name": "insert_image_one",
    "plural": false,
    "selections": [
      (v4/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "profile_picture_id",
            "variableName": "imgId"
          }
        ],
        "kind": "ObjectValue",
        "name": "_set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "userId"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "user",
    "kind": "LinkedField",
    "name": "update_user_by_pk",
    "plural": false,
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "profile_picture_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "certsToVerifyObjects"
      }
    ],
    "concreteType": "certificates_to_verify_mutation_response",
    "kind": "LinkedField",
    "name": "insert_certificates_to_verify",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteOrientationStepsUIProfilePhotoMutation",
    "selections": (v5/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SiteOrientationStepsUIProfilePhotoMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "74b4995d8a983c11f314e7fedfac5b56",
    "id": null,
    "metadata": {},
    "name": "SiteOrientationStepsUIProfilePhotoMutation",
    "operationKind": "mutation",
    "text": "mutation SiteOrientationStepsUIProfilePhotoMutation(\n  $certsToVerifyObjects: [certificates_to_verify_insert_input!]!\n  $userId: uuid!\n  $imgId: uuid!\n  $imageObj: image_insert_input!\n) {\n  insert_image_one(object: $imageObj) {\n    id\n  }\n  update_user_by_pk(_set: {profile_picture_id: $imgId}, pk_columns: {id: $userId}) {\n    id\n    profile_picture_id\n  }\n  insert_certificates_to_verify(objects: $certsToVerifyObjects) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "bce6144428c9331e8c3c539cd2c0062b";

export default node;
