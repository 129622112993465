/**
 * @generated SignedSource<<a3671d2f4bed114ec38f6d4eb64a66c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { order_by, project_worker_bool_exp, project_worker_order_by } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AnonProjectWorkerTableQuery$variables = {
  after?: string | null | undefined;
  first: number;
  gcId?: string | null | undefined;
  includeCrew: boolean;
  includeOrientation: boolean;
  order_by: ReadonlyArray<project_worker_order_by>;
  where: project_worker_bool_exp;
};
export type AnonProjectWorkerTableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AnonProjectWorkerTable_project_worker" | "AnonProjectWorkerTable_total">;
};
export type AnonProjectWorkerTableQuery = {
  response: AnonProjectWorkerTableQuery$data;
  variables: AnonProjectWorkerTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gcId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeCrew"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeOrientation"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order_by"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v7 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v8 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v9 = {
  "kind": "Variable",
  "name": "order_by",
  "variableName": "order_by"
},
v10 = {
  "kind": "Variable",
  "name": "where",
  "variableName": "where"
},
v11 = [
  (v10/*: any*/)
],
v12 = [
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v15 = [
  (v13/*: any*/),
  (v14/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AnonProjectWorkerTableQuery",
    "selections": [
      {
        "args": [
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "kind": "Variable",
            "name": "gcId",
            "variableName": "gcId"
          },
          {
            "kind": "Variable",
            "name": "includeCrew",
            "variableName": "includeCrew"
          },
          {
            "kind": "Variable",
            "name": "includeOrientation",
            "variableName": "includeOrientation"
          },
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "AnonProjectWorkerTable_project_worker"
      },
      {
        "args": (v11/*: any*/),
        "kind": "FragmentSpread",
        "name": "AnonProjectWorkerTable_total"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "AnonProjectWorkerTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v12/*: any*/),
        "concreteType": "project_workerConnection",
        "kind": "LinkedField",
        "name": "project_worker_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_workerEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_worker",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": "pk",
                    "args": null,
                    "filters": null,
                    "handle": "pk",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "worker_role",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hard_hat_number",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created_at",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "worker_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "role",
                        "storageKey": null
                      },
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "condition": "includeCrew",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project_crew",
                        "kind": "LinkedField",
                        "name": "project_crew",
                        "plural": false,
                        "selections": (v15/*: any*/),
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": (v15/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor",
                    "plural": false,
                    "selections": (v15/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker_title",
                    "kind": "LinkedField",
                    "name": "title",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "text_translation",
                        "kind": "LinkedField",
                        "name": "translation",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "en",
                            "storageKey": null
                          },
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "condition": "includeOrientation",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user_orientation",
                        "kind": "LinkedField",
                        "name": "user_orientation",
                        "plural": false,
                        "selections": [
                          {
                            "alias": "corporateResults",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "order_by",
                                "value": [
                                  {
                                    "completed_at": "asc_nulls_last"
                                  },
                                  {
                                    "created_at": "asc"
                                  }
                                ]
                              },
                              {
                                "fields": [
                                  {
                                    "kind": "Literal",
                                    "name": "_or",
                                    "value": [
                                      {
                                        "status": {
                                          "_eq": "completed"
                                        }
                                      },
                                      {
                                        "completed_at": {
                                          "_is_null": false
                                        }
                                      }
                                    ]
                                  },
                                  {
                                    "fields": [
                                      {
                                        "fields": [
                                          {
                                            "kind": "Variable",
                                            "name": "_eq",
                                            "variableName": "gcId"
                                          }
                                        ],
                                        "kind": "ObjectValue",
                                        "name": "general_contractor_id"
                                      },
                                      {
                                        "kind": "Literal",
                                        "name": "type",
                                        "value": {
                                          "_eq": "corporate"
                                        }
                                      }
                                    ],
                                    "kind": "ObjectValue",
                                    "name": "orientation"
                                  }
                                ],
                                "kind": "ObjectValue",
                                "name": "where"
                              }
                            ],
                            "concreteType": "orientation_result",
                            "kind": "LinkedField",
                            "name": "all_user_orientation_results",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "completed_at",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "status",
                                "storageKey": null
                              },
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "orientated_at",
                            "storageKey": null
                          },
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ]
                  },
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v12/*: any*/),
        "filters": [
          "where",
          "order_by"
        ],
        "handle": "connection",
        "key": "AnonProjectWorkerTable_project_worker_connection",
        "kind": "LinkedHandle",
        "name": "project_worker_connection"
      },
      {
        "alias": "allProjectWorkersConnection",
        "args": (v11/*: any*/),
        "concreteType": "project_workerConnection",
        "kind": "LinkedField",
        "name": "project_worker_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_workerEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_worker",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8c5a25bd0ce42c64fbc62e2b5867e04e",
    "id": null,
    "metadata": {},
    "name": "AnonProjectWorkerTableQuery",
    "operationKind": "query",
    "text": "query AnonProjectWorkerTableQuery(\n  $first: Int!\n  $after: String\n  $where: project_worker_bool_exp!\n  $order_by: [project_worker_order_by!]!\n  $includeCrew: Boolean!\n  $includeOrientation: Boolean!\n  $gcId: uuid\n) {\n  ...AnonProjectWorkerTable_project_worker_3Eq00C\n  ...AnonProjectWorkerTable_total_3FC4Qo\n}\n\nfragment AnonProjectWorkerTable_project_worker_3Eq00C on query_root {\n  project_worker_connection(first: $first, after: $after, where: $where, order_by: $order_by) {\n    edges {\n      node {\n        pk: id\n        worker_role\n        hard_hat_number\n        created_at\n        title_id\n        worker_id\n        user {\n          name\n          email\n          role\n          id\n        }\n        project_crew @include(if: $includeCrew) {\n          name\n          id\n        }\n        project {\n          name\n          id\n        }\n        subcontractor {\n          name\n          id\n        }\n        title {\n          translation {\n            en\n            id\n          }\n          id\n        }\n        user_orientation @include(if: $includeOrientation) {\n          corporateResults: all_user_orientation_results(where: {orientation: {type: {_eq: \"corporate\"}, general_contractor_id: {_eq: $gcId}}, _or: [{status: {_eq: \"completed\"}}, {completed_at: {_is_null: false}}]}, order_by: [{completed_at: asc_nulls_last}, {created_at: asc}]) {\n            completed_at\n            status\n            id\n          }\n          orientated_at\n          id\n        }\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment AnonProjectWorkerTable_total_3FC4Qo on query_root {\n  allProjectWorkersConnection: project_worker_connection(where: $where) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "527c03a92c645c570cb2ed2757d50e48";

export default node;
