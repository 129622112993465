/**
 * @generated SignedSource<<fc7b5ded46e07ac342735451d11bc911>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { order_by, project_subcontractor_employee_bool_exp, project_subcontractor_employee_order_by } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectSubcontractorEmployeeTableQuery$variables = {
  after?: string | null | undefined;
  first: number;
  order_by: ReadonlyArray<project_subcontractor_employee_order_by>;
  where: project_subcontractor_employee_bool_exp;
};
export type ProjectSubcontractorEmployeeTableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProjectSubcontractorEmployeeTable_projectSubcontractorEmployee" | "ProjectSubcontractorEmployeeTable_total">;
};
export type ProjectSubcontractorEmployeeTableQuery = {
  response: ProjectSubcontractorEmployeeTableQuery$data;
  variables: ProjectSubcontractorEmployeeTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order_by"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v4 = {
  "kind": "Variable",
  "name": "where",
  "variableName": "where"
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order_by",
    "variableName": "order_by"
  },
  (v4/*: any*/)
],
v6 = [
  (v4/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectSubcontractorEmployeeTableQuery",
    "selections": [
      {
        "args": (v5/*: any*/),
        "kind": "FragmentSpread",
        "name": "ProjectSubcontractorEmployeeTable_projectSubcontractorEmployee"
      },
      {
        "args": (v6/*: any*/),
        "kind": "FragmentSpread",
        "name": "ProjectSubcontractorEmployeeTable_total"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProjectSubcontractorEmployeeTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "project_subcontractor_employeeConnection",
        "kind": "LinkedField",
        "name": "project_subcontractor_employee_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_subcontractor_employeeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_subcontractor_employee",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subcontractor_employee_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "obs_notify_user",
                    "kind": "LinkedField",
                    "name": "notify_for_obs",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "risk_level_value",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor_employee",
                    "kind": "LinkedField",
                    "name": "subcontractor_employee",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "phone_number",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "created_password",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "limit",
                                "value": 1
                              },
                              {
                                "kind": "Literal",
                                "name": "where",
                                "value": {
                                  "accepted_at": {
                                    "_is_null": true
                                  },
                                  "claiming_account": {
                                    "_eq": true
                                  }
                                }
                              }
                            ],
                            "concreteType": "invite",
                            "kind": "LinkedField",
                            "name": "invites",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "accepted_at",
                                "storageKey": null
                              },
                              (v7/*: any*/)
                            ],
                            "storageKey": "invites(limit:1,where:{\"accepted_at\":{\"_is_null\":true},\"claiming_account\":{\"_eq\":true}})"
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "subcontractor_employee_title",
                        "kind": "LinkedField",
                        "name": "employee_title",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name_text",
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emergency_contact",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "ProjectSubcontractorEmployeeTable_project_subcontractor_employee_connection",
        "kind": "LinkedHandle",
        "name": "project_subcontractor_employee_connection"
      },
      {
        "alias": "allproject_subcontractor_employeeConnection",
        "args": (v6/*: any*/),
        "concreteType": "project_subcontractor_employeeConnection",
        "kind": "LinkedField",
        "name": "project_subcontractor_employee_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_subcontractor_employeeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_subcontractor_employee",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "82301009fc689c263931837137e46ff4",
    "id": null,
    "metadata": {},
    "name": "ProjectSubcontractorEmployeeTableQuery",
    "operationKind": "query",
    "text": "query ProjectSubcontractorEmployeeTableQuery(\n  $first: Int!\n  $after: String\n  $where: project_subcontractor_employee_bool_exp!\n  $order_by: [project_subcontractor_employee_order_by!]!\n) {\n  ...ProjectSubcontractorEmployeeTable_projectSubcontractorEmployee_4g04uc\n  ...ProjectSubcontractorEmployeeTable_total_3FC4Qo\n}\n\nfragment ProjectSubcontractorEmployeeTable_projectSubcontractorEmployee_4g04uc on query_root {\n  project_subcontractor_employee_connection(first: $first, after: $after, where: $where, order_by: $order_by) {\n    edges {\n      node {\n        id\n        pk: id\n        project {\n          id\n          pk: id\n          name\n        }\n        subcontractor_employee_id\n        notify_for_obs {\n          id\n          pk: id\n          risk_level_value\n        }\n        subcontractor_employee {\n          user {\n            name\n            email\n            phone_number\n            created_password\n            invites(where: {claiming_account: {_eq: true}, accepted_at: {_is_null: true}}, limit: 1) {\n              accepted_at\n              id\n            }\n            id\n          }\n          employee_title {\n            name_text\n            id\n          }\n          id\n        }\n        emergency_contact\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ProjectSubcontractorEmployeeTable_total_3FC4Qo on query_root {\n  allproject_subcontractor_employeeConnection: project_subcontractor_employee_connection(where: $where) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3f1bad7f882bc67529735ed58a1b6676";

export default node;
