import React from "react";
import { Popover, PopoverProps } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

/// Primarily, use `content` prop

type ExplanationIconProps = Omit<PopoverProps, "children">;

const ExplanationIcon: React.FC<ExplanationIconProps> = (props) => {
  return (
    <Popover
      {...props}
      classNames={{
        root: "max-w-32",
      }}
    >
      <InfoCircleOutlined className={`text-static-secondary`} />
    </Popover>
  );
};

export default ExplanationIcon;
